import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby";
import { Feed } from "@/components/Feed";
import { Layout } from "@/components/Layout";
import { Meta } from "@/components/Meta";
import { Page } from "@/components/Page";
import { Sidebar } from "@/components/Sidebar";
import { Pagination } from "@/components/Pagination";
import { useSiteMetadata } from "@/hooks";
import { AllMarkdownRemark, PageContext } from "@/types";
import { Edge } from "@/types";

interface Props {
  data: {
    allMarkdownRemark: AllMarkdownRemark;
  };
  pageContext: PageContext;
}

const IndexTemplate: React.FC<Props> = ({ data, pageContext }: Props) => {
  const { pagination } = pageContext;
  const { hasNextPage, hasPrevPage, prevPagePath, nextPagePath } = pagination;
  const { edges } = data.allMarkdownRemark;

  const backgrounds = [
    "#FF5733", // Red
    "#33FF57", // Green
    "#3357FF", // Blue
    "#FF33A1", // Pink
    "#FFC733", // Yellow
    // Add more colors or URLs to images as needed
  ];

  const [background, setBackground] = useState(backgrounds[0]);

  useEffect(() => {
    const changeBackground = () => {
      const randomIndex = Math.floor(Math.random() * backgrounds.length);
      setBackground(backgrounds[randomIndex]);
    };

    // Change background when component mounts
    changeBackground();

    // Optionally, you could also set an interval to change the background every few seconds
    const intervalId = setInterval(changeBackground, 2000);

    // Clean up interval on component unmount
    // return () => clearInterval(intervalId);
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <Layout>
      <div className="right-side-img" style={{ backgroundColor: background }}>
        {/* Any additional content for right-side-img */}
      </div>

      <Sidebar />
      <div className="wrapper">
        <Page>
          <div className='post-list'>
            <div className="header-section_left">
              <h3>マット</h3>
              <h2>
                👋 <span className="step-br">Matt Stenquist</span>, Product Engineer currently in Chicago. 
                Thoughts on: <span className="step-br"> Design 🧱</span>,
                <span className="step-br"> Startups 🚀</span>, 
                <span className="amp">&amp;</span> <span className="step-br"> Gamedev 🕹️. </span> 
                Prev &mdash; Tokyo, Switzerland, London, Netherlands.
              </h2>
              <div className="header-section_right">
                <div className="ico-group"></div>
              </div>
            </div>
          </div>

          <div className="featured-title"><h2>📅 Latest</h2></div>
          <Feed edges={edges} />

          <div className="featured-title"><h2>🗞️ Featured</h2></div>
          <div className="anim-wrap">
            <div className="featured">
              <div className="featured-inner-title">🎨 Design Process of Mainspace</div>
              <div className="featured-inner-tag">🕹️ Design Systems</div>
              <div className="image"></div>
            </div>
          </div>
        </Page>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query IndexTemplate($limit: Int!, $offset: Int!) {
    allMarkdownRemark(
      limit: $limit
      skip: $offset
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "post" }, draft: { ne: true } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            category
            title
            draft
            emoji
            tags
            date
            slug
          }
        }
      }
    }
  }
`;

export const Head: React.FC<Props> = ({ pageContext }) => {
  const { title, subtitle } = useSiteMetadata();
  const {
    pagination: { currentPage: page },
  } = pageContext;
  const pageTitle = page > 0 ? `Posts - Page ${page} - ${title}` : title;

  return <Meta title={pageTitle} description={subtitle} />;
};

export default IndexTemplate;